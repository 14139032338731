import React, { Component } from 'react';
import { withMixpanel } from 'gatsby-plugin-mixpanel';
import YouTube from 'react-youtube';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import AppStoreImage from '../components/images/AppStoreImage';
import HeroImage from '../components/images/HeroImage';
import BonfireImage from '../components/images/BonfireImage';
import DefinitionImage from '../components/images/DefinitionImage';
import NookImage from '../components/images/NookImage';
import PatioImage from '../components/images/PatioImage';
import SvgCharts from '../svg/SvgCharts';

class Index extends Component {
    render() {
      return (
        <Layout>
          <SEO title="Notetaking for the aspirational" />
          <section className="pt-20 md:pt-40">
            <div className="container mx-auto px-8 lg:flex">
              <div className="lg:text-left lg:w-1/2">
                <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                  Insightful notetaking for the aspirational
                </h1>
                <p className="text-xl lg:text-2xl mt-6 font-light">
                  <b>Let's get next</b> before your peers
                </p>
                <div className="mt-2">

                </div>
              </div>
              <div className="lg:w-1/2 mt-6">
                <HeroImage />
              </div>
            </div>
          </section>
          <section id='video' className="flex justify-center mx-auto my-20 py-24 rounded-lg text-center">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '800px' }}>
            <YouTube style={{height: '500px', width: '100%'}} opts={{ width: '100%', height: '100%' }} videoId="j_I39X-nl3U" />
          </div>
          </section>
          <section id="testimonials" className="py-20 lg:py-40">
            <div className="container mx-auto">
              <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
              <div className="flex flex-col md:flex-row md:-mx-3">
                {customerData.map(customer => (
                  <div key={customer.customerName} className="flex-1 px-3">
                    <CustomerCard customer={customer} />
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section id='download' className="container mx-auto my-20 py-24 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">#LetsGetNext</h3>
            <p className="text-lg mb-2 -mt-2 font-thin">before your peers</p>
            <div>

            </div>
          </section>
        </Layout>
      );
    }
};

export default withMixpanel()(Index);
