export default [
  {
    title: 'Seamless Precision',
    content:
      'Tasks that once required tedious attention now unfold with seamless precision',
    customerName: 'Andrea',
    customerTitle: 'Project Manager'
  },
  {
    title: 'Efficiency and Elegance',
    content:
      'Exemplifies the zenith of efficiency and elegance in professional endeavors',
    customerName: 'Taylor',
    customerTitle: 'Technical Product Manager'
  },
  {
    title: 'Going Modern',
    content:
      '..from a mere productivity tool to a lifestyle enhancer for the modern professional',
    customerName: 'Kayla',
    customerTitle: 'Vice President, Systems Integration',
  }
];
